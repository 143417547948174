<template>
  <form style="margin-top:15px" novalidate class="md-alignment-center-center md-size-100 reset-password-form"
        @submit.prevent="validateReset">
    <md-card class="md-layout-item md-size-40 md-small-size-70 reset-password-card" style="margin: 0 auto;">
      <md-card-header>
        <div class="md-title"> {{ $t('resetPassword.title') }}</div>
        <div>
          <img class="forgot-logo" :src="`${publicPath}AMF_header_logo.png`" width="50%">
        </div>
      </md-card-header>
      <md-card-content>
        <md-field :class="[getValidationClass('username'),'md-field-auth']">
          <md-input name="username"
                    id="username"
                    autocomplete="Username"
                    v-model="form.username"
                    :disabled="form.username ? true : false"
                    @focus="clearErrors('username')" class="md-input-auth input-with-border" placeholder="Username"/>
          <div class="md-error" v-if="!$v.form.username.required">
            {{ $t('requiredField.minLengthUsername') }}
          </div>
          <div class="md-error" v-else-if="!$v.form.username.minlength">
            {{ $t('login.') }}
          </div>
        </md-field>
        <md-field :class="[getValidationClass('password'),'md-field-auth']">
          <input name="password"
                 id="password"
                 type="password"
                 autocomplete="password"
                 v-model="form.password"
                 :disabled="isLoading" class="md-input-auth-password input-with-border" placeholder="Password"/>
          <div class="md-error" v-if="!$v.form.password.required">
            {{ $t('requiredField') }}
          </div>
          <div class="md-error" v-else-if="!$v.form.password.minlength">
            {{ $t('login.minLengthPassword') }}
          </div>
        </md-field>
        <md-field :class="[getValidationClass('password-confirm'),'md-field-auth']">
          <input name="password-confirm"
                 id="password-confirm"
                 type="password"
                 autocomplete="off"
                 v-model="form.passwordConfirm"
                 :disabled="isLoading" class="md-input-auth-password input-with-border" placeholder="Confirm Password"/>
          <div class="md-error" v-if="!$v.form.passwordConfirm.required">
            {{ $t('requiredField') }}
          </div>
          <div class="md-error" v-else-if="!$v.form.passwordConfirm.minlength">
            {{ $t('resetPassword.passwordMinLength') }}
          </div>
        </md-field>
        <div class="error-message" v-for="error in errors.username" :key="error">{{error}}</div>
        <div class="error-message" v-for="error in errors.password" :key="error">{{error}}</div>
      </md-card-content>
      <md-progress-bar :class="md-accent" md-mode="indeterminate" v-if="isLoading" />
      <md-card-actions class="forgot-btn-container">
        <md-button type="submit" class="md-raised md-accent forgot-auth-btn" :disabled="isLoading">
          {{ $t('resetPassword.resetBtn') }}
        </md-button>
      </md-card-actions>
    </md-card>
  </form>
</template>
<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
} from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import { RESET_PASSWORD } from '@/store/modules/auth/authActions.type';

export default {
  name: 'UserResetPassword',
  props: {
    token: {
      type: String,
    },
    username: {
      type: String,
    },
  },
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      username: null,
      password: null,
      passwordConfirm: null,
    },
    publicPath: process.env.BASE_URL,
  }),
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      password: {
        required,
        minLength: minLength(8),
      },
      passwordConfirm: {
        required,
        minLength: minLength(8),
      },
    },
  },
  mounted() {
    this.form.username = this.username ?? null;
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.form.username = null;
      this.form.password = null;
    },
    validateReset() {
      this.$v.$touch();
      this.errors = {};
      if (!this.$v.$invalid) {
        this.loginAction();
      }
    },
    loginAction() {
      const payload = {
        username: this.form.username,
        password: this.form.password,
        reset_token: this.token,
        password_confirm: this.form.passwordConfirm,
      };
      this.$store.dispatch(RESET_PASSWORD, payload).then((response) => {
        this.$log.info(RESET_PASSWORD, payload);
        this.$toasted.show(response.message, {
          type: 'success',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
        });
        this.clearForm();
        this.$router.push('/user/login');
      }).catch((error) => {
        this.$log.error(RESET_PASSWORD, error);
        this.$toasted.show(error.message, {
          type: 'error',
          position: 'top-right',
          duration: 5000,
          icon: 'alert',
        });
        if (error.status === 'validation_error' && error.errors) {
          this.errors = error.errors;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/common/auth/auth-panel.scss';
</style>
