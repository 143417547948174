<template>
  <md-card style="margin-bottom: 50px" class="md-layout-item md-size-100 md-small-size-100 forgot-layout">
    <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="isLoading"/>
  </md-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import loaderMixin from '@/mixins/loaderMixin';
import { CONFIRM_ACCOUNT } from '@/store/modules/auth/authActions.type';

export default {
  name: 'UserResetPassword',
  props: {
    token: String,
  },
  mixins: [
    validationMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      token: null,
    },
    publicPath: process.env.BASE_URL,
  }),
  validations: {
    form: {
      token: {
        required,
      },
    },
  },
  mounted() {
    this.confirmAction();
  },
  methods: {
    confirmAction() {
      const payload = {
        confirmation_token: this.token,
      };
      this.$store.dispatch(CONFIRM_ACCOUNT, payload)
        .then((response) => {
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.$router.push('/user/step1');
        })
        .catch((error) => {
          this.$log.error(CONFIRM_ACCOUNT, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
          this.$router.push('/');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common/auth/auth-panel.scss';
</style>
